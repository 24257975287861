import * as React from 'react'

const Footer = () => {
    return (<footer className="bg-dark text-white py-5 px-3">
        <div className='container-lg'>
            <div className='row'>
                <div className='col-12 col-md-3'>
                    <h3 className='mb-0'>popular articles</h3>
                    <ul className='list-unstyled'>
                        <li><a href="https://blog.minimacode.com/monolith-microservices-serverless-explained/" className='text-white'>Monolith vs Microservices</a></li>
                        <li><a href="https://blog.minimacode.com/cap-firebase-spending/" className='text-white'>Limit Firebase spending</a></li>
                        <li><a href="https://blog.minimacode.com/use-firebase-for-your-project/" className='text-white'>Is Firebase right for you?</a></li>
                    </ul>
                </div>
                <div className='col-12 col-md-6 text-md-center flex-fill'>
                    <h3 className='mb-0'>&nbsp;</h3>
                    <p className='mb-0'>
                        Copyright 2023<br />
                        MinimaCode SRL<br />
                        <a
                            rel={'nofollow'}
                            target={'_blank'}
                            href="https://minimacode.notion.site/minimacode-com-privacy-policy-94407523da2444a58487cdc5f884ea33"
                            className='text-white'
                        >privacy policy</a>
                    </p>
                </div>
                <div className='col-12 col-md-3'>
                    <img
                        className='w-100 mx-auto mt-md-0 mt-5'
                        src="https://api.thegreenwebfoundation.org/greencheckimage/minimacode.com?nocache=true"
                        alt="This website is hosted Green - checked by thegreenwebfoundation.org"
                    />
                </div>
            </div>
        </div>
    </footer>)
}

export default Footer