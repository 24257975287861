import React from 'react'

const InkwellForm = (props: React.HTMLAttributes<HTMLElement>) => (
    <form action="https://app.convertkit.com/forms/4799541/subscriptions" className="form form-inline" method="post" data-sv-form="4799541" data-uid="f47f2e83f9" data-format="inline" data-version="5"
        data-options="{&quot;settings&quot;:{&quot;after_subscribe&quot;:{&quot;action&quot;:&quot;message&quot;,&quot;success_message&quot;:&quot;Thanks for signing up! We'll be in touch soon.&quot;,&quot;redirect_url&quot;:&quot;&quot;},&quot;analytics&quot;:{&quot;google&quot;:null,&quot;fathom&quot;:null,&quot;facebook&quot;:null,&quot;segment&quot;:null,&quot;pinterest&quot;:null,&quot;sparkloop&quot;:null,&quot;googletagmanager&quot;:null},&quot;modal&quot;:{&quot;trigger&quot;:&quot;timer&quot;,&quot;scroll_percentage&quot;:null,&quot;timer&quot;:5,&quot;devices&quot;:&quot;all&quot;,&quot;show_once_every&quot;:15},&quot;powered_by&quot;:{&quot;show&quot;:false,&quot;url&quot;:&quot;https://convertkit.com/features/forms?utm_campaign=poweredby&amp;utm_content=form&amp;utm_medium=referral&amp;utm_source=dynamic&quot;},&quot;recaptcha&quot;:{&quot;enabled&quot;:false},&quot;return_visitor&quot;:{&quot;action&quot;:&quot;custom_content&quot;,&quot;custom_content&quot;:&quot;Thanks for signing up! We'll be in touch soon.&quot;},&quot;slide_in&quot;:{&quot;display_in&quot;:&quot;bottom_right&quot;,&quot;trigger&quot;:&quot;timer&quot;,&quot;scroll_percentage&quot;:null,&quot;timer&quot;:5,&quot;devices&quot;:&quot;all&quot;,&quot;show_once_every&quot;:15},&quot;sticky_bar&quot;:{&quot;display_in&quot;:&quot;top&quot;,&quot;trigger&quot;:&quot;timer&quot;,&quot;scroll_percentage&quot;:null,&quot;timer&quot;:5,&quot;devices&quot;:&quot;all&quot;,&quot;show_once_every&quot;:15}},&quot;version&quot;:&quot;5&quot;}" min-width="400 500 600 700 800">

        <div data-style="minimal">
            <ul className="formkit-alert formkit-alert-error" data-element="errors" data-group="alert"></ul>
            <div data-element="fields" data-stacked="false" className="seva-fields formkit-fields d-flex input-group-lg mt-5">
                <div className="formkit-field flex-fill">
                    <label className="visually-hidden" htmlFor="emailInput">email</label>
                    <input className="form-control bg-white" id="emailInput" name="email_address" aria-label="Email Address" placeholder="name@company.com" required={true} type="email" />
                </div>
                <button data-element="submit" className="btn btn-primary ms-3">
                    <div className="formkit-spinner"><div></div><div></div><div></div></div><span className="">Register</span>
                </button>
            </div>
        </div>
        <script src="https://f.convertkit.com/ckjs/ck.5.js"></script>
    </form>
)


export default InkwellForm