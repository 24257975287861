import 'bootstrap/dist/css/bootstrap.min.css'
import '../styles/base.css'

import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import InkwellForm from "../components/InkwellConvertkitForm"
import Footer from "../components/Footer"

const MastermindPage = () => (
    <main className="lp">
        <section id="mm-hero">
            <StaticImage
                class="invisible"
                style={{ maxHeight: '720px'}}
                src="../images/mm-hero.avif"
                alt="Dublin Library"
            />
        </section>
        <section className="px-3">
            <div className="container px-3 px-md-5 py-3 bg-yellow" style={{ marginTop: '-60px' }}>
                <h1 className="mt-5 text-white">Inkwell Leaders Mastermind</h1>
                <h2 className="mb-5 text-white subtitle">Elevate your marketing strategy</h2>
            </div>
        </section>
        <section className="pt-3 px-3" id="the-how">
            <div className="container-sm">
                <p className="my-5">Our exclusive online events are specifically <strong>tailored to marketing professionals in the book publishing industry</strong>. You can receive the knowledge, resources, and support you need to take your marketing strategy to the next level.</p>
                <p className="my-5">Through our interactive discussions, case studies, and networking opportunities, you'll have the chance to connect with industry leaders and experts, share your own experiences and challenges, and learn from the collective wisdom of the group.</p>
                <p className="my-5">You will also have access to our online community where you can continue the conversation and access exclusive resources and tools. Join the Inkwell Leaders Mastermind and take your marketing skills to the next level.</p>
            </div>
        </section>

        <section className="px-3" id='the-what'>
            <div className="container-sm py-3">
                <hr className="yellow mb-5" style={{ height: '4px' }} />
                <h2 className="my-5">Requirements for joining</h2>
                <p>Our mastermind events are designed for marketing professionals in senior roles within book publishing companies. <strong>Attendance is subject to the following criteria:</strong></p>
                <BulletedItem
                    step={1}
                    text="Prior attendance to one of our events"
                />
                <BulletedItem
                    step={2}
                    text="Direct invitation and approval"
                />
                <BulletedItem
                    step={3}
                    text="Being in a senior position within a book publishing company"
                />
                <hr className="yellow mt-5" style={{ height: '4px' }} />
            </div>
        </section>


        <section className="px-3">
            <div className="container-sm my-4 py-3">
                <h2 className="">Cost</h2>
                <p>The Inkwell Leaders Mastermind is a closed circuit event and it is free to attend.</p>
            </div>
        </section>


        <section id="mm-footer" className="p-3 mt-5 text-white">
            <div className="container-sm">
                <h2 className="mt-5 no-underline">Register</h2>
                <p>Don't miss out on this opportunity to elevate your marketing strategy and sign up below.</p>
                <div className="my-5">
                    <InkwellForm />
                </div>
                <p>After registration, you will be contacted for a short call to get to know each other and assess suitability for joining the live events.</p>
                {/* <hr className="text-white" style={{ height: '4px' }} /> */}
            </div>
            <div className='container-sm text-center mt-5 py-5'>

            </div>
        </section>
        <Footer />
    </main>
)

const BulletedItem = (props: {
    text: string,
    step: number,
}) => {
    return <div className="my-1 py-2 d-flex">
        <div className="d-flex flex-shrink flex-column justify-content-center ps-2 pe-3">
            <div className="text-white bg-dark d-flex flex-column justify-content-center" style={{ height: '36px', width: '36px', borderRadius: '36px' }}>
                <p className="text-center mb-0"><strong>{props.step}</strong></p>
            </div>
        </div>
        <div>
            <p className="mb-0">{props.text}</p>
        </div>
    </div>
}

export default MastermindPage